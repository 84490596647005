import React from "react";
import { FaUserAlt } from 'react-icons/fa';

import avatar from './../../images/avatar-42.png';
//import avatar from './../../images/LBF-Agent.png';
//import avatar from './../../images/far-logo.png';
import './Avatar.scss'

const imageHeight = 42;

function Avatar(props){

  const image = props.type === 'user' ? <FaUserAlt size={27} /> : <img src={avatar} height={imageHeight} width={imageHeight} alt='Lumina agent avatar' />

  return (
    <rlcb-avatar>{image}</rlcb-avatar>
  );
}

export default Avatar;