import React from "react";
import DOMPurify from 'dompurify';
import MessageTime from "../MessageTime/MessageTime";

import './MessageText.scss';
import Citations from "../../Citations/Citations";

function MessageText(props){
  return (
    <div className='message'>
      { typeof props.content === 'object' ? <div className='content'>{ props.content }</div> : <div className='content' dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize(props.content, { ADD_ATTR: ['target'] }) }} /> }
      <Citations 
        citations={ props.citations } 
        numShow={ props.numShow }
        />
      <MessageTime datetime={ props.datetime } />
    </div>
  );
}

export default MessageText;